import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/gravlax.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import gravlaxcover from "../images/Blinis.jpg" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionGravlax from "../images/StopMotionGravlax.mp4"
const gravlaxSalmao = () => (
<Layout>
   <SEO title="Peixe Fácil - Gravlax de Salmão" />
   <div className="wrapper-bg-grav">
      <div className="center-col-bg">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-grav">
      <div className="wrapper-container">
        
         <div className="titulo absara blue-marine">
            <p>Gravlax de Salmão</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> A forma saborosa e moderna de se deliciar com salmão. </p>
         </div>
      </div>
   </div>
   <div id="recipes-section-grav">
      <div className="wrapper-section">
        <div className="col-1">
        <video className="video-class" preload='auto' controls autoPlay loop playsInline muted>
            <source src={StopMotionGravlax} type="video/mp4" />
          </video>
        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Sanduiche de salmão com pepino
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">3 Fatias de pão de cereais</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Fatias de Gravlax de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">50g de queijo fresco para barrar</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">30g de rúcula</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 Cebola roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Tomate</span>
                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Corte o pepino e o tomate em fatias muito finas</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Abra o pão e barre com queijo fresco previamente temperado com sal e pimenta</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Disponha as fatias de salmão, as fatias de pepino, a rúcula e cebola roxa picada no pão por camadas</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Corte o pão a meio e sirva</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            434
                        </div>
                        <div className="titulo sneak-regular">
                            28
                        </div>
                        <div className="titulo sneak-regular">
                           8
                        </div>
                        <div className="titulo sneak-regular">
                      21
                        </div>
                        <div className="titulo sneak-regular">
                         10
                        </div>
                        <div className="titulo sneak-regular">
                         25
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
      </div>

      <div className="wrapper-section-2">
        <div className="col-1-mobile">

        </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Blinis com queijo creme e gravlax
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de blinis</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">100g de Gravlax de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 ramo de cebolinho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">60g de queijo creme para barrar</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 limão</span><br></br>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Misture o queijo creme com sal, pimenta e sumo de limão</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Barre os blinis com a mistura de queijo</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Disponha as fatias de salmão por cima</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Pique o cebolinho e polvilhe sobre os blinis</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            585
                        </div>
                        <div className="titulo sneak-regular">
                            38
                        </div>
                        <div className="titulo sneak-regular">
                           10
                        </div>
                        <div className="titulo sneak-regular">
                      35
                        </div>
                        <div className="titulo sneak-regular">
                         15
                        </div>
                        <div className="titulo sneak-regular">
                         25
                        </div>
                        <div className="titulo sneak-regular">
                         0
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">
 
        </div>
      </div>

      <div className="wrapper-section-3">
      <div className="col-1">
 
 </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Salada verde com maçã e Gravlax de Salmão
              </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">80g mix de alfaces</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Gravlax de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 maçã verde Granny Smith </span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 uni. tomate cherry</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 cebola roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de vinagre de cidra</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 colher de sopa de mel</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span><br></br>



                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Emulsione o azeite, o vinagre de cidra e o mel</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Tempere a emulsão com sal e pimenta</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Coloque o mix de alfaces no centro de um prato e adicione as fatias de maçã, de salmão, a cebola roxa previamente picada e metades de tomate cherry</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Tempere com a emulsão</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            368
                        </div>
                        <div className="titulo sneak-regular">
                            22
                        </div>
                        <div className="titulo sneak-regular">
                          4
                        </div>
                        <div className="titulo sneak-regular">
                      25
                        </div>
                        <div className="titulo sneak-regular">
                         25
                        </div>
                        <div className="titulo sneak-regular">
                         17
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
       
      </div>
   </div>
   <div className="wrapper-3-grav">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default gravlaxSalmao